import { UserRole } from '../../shared/shared.interface';

const useUserPermissions = (jwtToken:string) => {
    if (!jwtToken)
        return [ 'read:issue' ];

    const jwtData = jwtToken.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    const assignedPermissions = decodedJwtData.permissions ? decodedJwtData.permissions : [ 'read:issue' ];
    return assignedPermissions;
};

export const useUserHasPermissions = async(jwtToken:string, requiredPermissions:string[]) => {
    const assignedPermissions = await useUserPermissions(jwtToken);
    if (assignedPermissions == null || !assignedPermissions.length)
        return false;

    const userPermissions = requiredPermissions.filter(element => assignedPermissions.includes(element));
    return (userPermissions.length > 0);
};

const useUserRoles = (jwtToken?:string) => {
    if (!jwtToken)
        return [ UserRole.GUEST ];

    const jwtData = jwtToken.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    const assignedRoles = decodedJwtData.roleTypes ? decodedJwtData.roleTypes : [ 'Guest' ];
    return assignedRoles;
};

export const useIsAdminUser = async(jwtToken?:string) => {
    const assignedUserRoles:string[] = await useUserRoles(jwtToken);
    if (assignedUserRoles == null || !assignedUserRoles.length)
        return false;

    const userRole = assignedUserRoles.filter(element => element === UserRole.ADMIN);
    return (userRole.length > 0);
};
